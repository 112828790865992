import './App.css';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import { MR_LOGO } from './assets/logos';
import * as images from './assets/images';
import ProjectButton from './components/projectButton';
import { Switch, Route } from 'react-router-dom';

function App() {
  const App = () => <div className="App">
    <Container fluid>
      <Row className="justify-content-center">
        <img alt="Medicina Resumida" className="app-logo" src={MR_LOGO}></img>
      </Row>
      <Row className="justify-content-center">
        <blockquote className="blockquote my-3">Conheça as nossas iniciativas:</blockquote>
      </Row>
      <Row className="justify-content-center">
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton url="https://www.youtube.com/user/medicinaresumida/" image={images.MR_CHANNEL} />
        </Col>
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton url="https://www.instagram.com/medresumida/" image={images.MR_INSTA} />
        </Col>
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton
            url="https://open.spotify.com/show/0Eqyv1TfHZje03rWtytzjN?si=x7kbkuGbTUyDael4CL3SIg"
            image={images.MR_PODCAST}
          />
        </Col>
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton url="https://www.sanarsaude.com/livro/colecao-medicina-resumida-volumes-1-2-3-4-5" image={images.MR_COLLECTION} />
        </Col>
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton
            url="https://mrcritic.app"
            image={images.MRCRITC} />
        </Col>
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton image={images.INTERROGATION} />
        </Col>
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton image={images.INTERROGATION} />
        </Col>
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton image={images.INTERROGATION} />
        </Col>
        <Col xs="4" lg="auto" className="text-center">
          <ProjectButton url="https://forms.gle/MVy883DAUhszrxU38" image={images.CONTACT} />
        </Col>

      </Row>
    </Container>
  </div>;

  const redirect = url => {
    window.location.replace(url);
    return null;
  }

  return (
    <Switch>
      <Route exact path="/" component={() => redirect("https://www.mrplus.app/pages/sobre")} />
      <Route exact path="/tree" component={App} />
      <Route exact path="/home" component={() => redirect("https://www.mrplus.app")} />
      <Route exact path="/mrplus" component={() => redirect("https://www.mrplus.app")} />
      <Route path="/experienciamr" component={() => redirect("https://www.mrplus.app/bundles/experienciamr")} />
      <Route path="/salavermelha" component={() => redirect("https://www.mrplus.app/courses/sala-vermelha")} />
      <Route exact path="/mrcritic" component={() => redirect("https://mrcritic.app")} />
      <Route exact path="/cursos" component={() => redirect("https://www.mrplus.app/collections?category=courses")} />
    </Switch>

  );
}

export default App;
