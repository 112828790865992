import React from 'react'
import './index.css'

function ProjectButton(props) {
    const {url, image} = props;
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <img alt="" className="projects-imgs" src={image}></img>
        </a>
    )
}

export default ProjectButton
